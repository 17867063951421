<template>
  <section class="app-container">
    <FilterLayout
        ref="filterLayoutRef"
        @doReset="resetForm"
        @doSearch="doSearch"
        @doCollapseEffect="getSize"
    >
      <template #customform>
        <a-form
            class="form rms-form"
            name="custom-validation"
            ref="formRef"
            layout="vertical"
            :model="formState"
        >
          <a-form-item label="Name" name="name" class="rms-form-item">
            <a-input
                v-model:value="formState.name"
                placeholder="请输入"
                allowClear
            />
          </a-form-item>
          <a-form-item label="Project Number/Project Name" name="projectNumOrName" class="rms-form-item">
            <a-input
                v-model:value="formState.projectNumOrName"
                placeholder="请输入"
                allowClear
            />
          </a-form-item>
          <a-form-item label="Grade" name="grade" class="rms-form-item">
            <a-select
                v-model:value="formState.grade"
                placeholder="请选择"
                allowClear
            >
              <a-select-option v-for="(item, index) in gradeOps" :key="index" :value="item.value">
                <span :title="item">{{ item.label }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="是否已填" name="isCompleted" class="rms-form-item">
            <a-select
                allow-clear
                v-model:value="formState.isCompleted"
                placeholder="请选择"
            >
              <a-select-option key="Y" value="1">是</a-select-option>
              <a-select-option key="N" value="0">否</a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="导出">
            <a-button type="primary" ghost @click="exportReviewData" v-auth="['system:AssignmentsEMReview:exportReviewData']" class="ml12">
              <ExportOutlined />
            </a-button>
          </Tooltip>
          <Tooltip title="导入">
            <a-button
                class="ml12"
                type="primary"
                ghost
                @click="uploadReviewData"
                v-if="isImport"
            >
              <ImportOutlined />
            </a-button>
          </Tooltip>
          <a-button
              class="ml12"
              type="primary"
              @click="batchRoleauthorization"
              :disabled="selectedState.selectedId.length === 0"
              v-auth="['system:AssignmentsEMReview:batchRoleauthorization']"
          >
            批量授权
          </a-button>
          <a-button
              class="ml12"
              type="primary"
              @click="batchReviewClick"
              :disabled="selectedState.selectedId.length === 0"
              v-auth="['system:AssignmentsEMReview:batchReviewClick']"
          >
            批量评分
          </a-button>
        </div>
      </template>
    </FilterLayout>
    <div class="table-wrap">
      <a-table
          :columns="reviewColumns"
          :data-source="reviewList"
          rowKey="uniqueNumber"
          :pagination="pagination"
          :row-selection="{
            selectedRows: selectedState.selectedList,
            selectedRowKeys: selectedState.selectedId,
            onSelect: onSelect,
            onChange: onSelectChange,
          }"
          :scroll="{
            x: 5820,
            y: tableHeight,
            scrollToFirstRowOnChange: true,
          }"
      >
        <template #operation="{ record }">
          <a-space>
          <span
              class="operationLink underline"
              @click="reviewClick(record)"
          >
            评分
          </span>
          </a-space>
        </template>
      </a-table>
    </div>
    <FillAppraises
        v-model:visible="appraisesVisible"
        title="Assignment Review"
        :form-state="appraisesState"
        @close-modal="closeAppraises"
        @submit-modal="submitAppraises"
    />
    <a-modal @cancel="authorizationCancel" width="26vw" :visible="authorizationVisible" title="EM 授权" class="authorizationClass">
      <a-form ref="authorizationFormRef" layout="vertical" :model="authorizationState" :rules="authorizationFormRules">
        <a-form-item label="被授权人" name="authorizationPeopleState">
          <a-select placeholder="请输入Name (例：Zhang, San)" v-model:value="authorizationState.authorizationPeopleState" optionFilterProp="label"
                    show-search allowClear showArrow
          >
            <a-select-option v-for="(item, index) in allempoweredStaffIdOps" :key="index" :value="item.full_name + ';' + item.email"
                             :label="
                item.full_name +
                item.full_name.replace(', ', '') +
                item.full_name.replace(', ', ',')
              ">
              <span :title="item.full_name + ' ' + item.email">{{ item.full_name + " " + item.email }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <div style="color: #ff4d4f;">注意: 授权后您和被授权人都可以对顾问进行项目评价，将以最后一次评价为准，您可以多次修改被授权人。</div>
      <template #footer>
        <div style="display: flex;justify-content: flex-end;">
          <a-space>
        <a-button @click="authorizationCancel">取消</a-button>
        <a-button type="primary" @click="authorizationConfirm">确定</a-button>
          </a-space>
        </div>
      </template>
    </a-modal>
    <import-modal
        v-model:visible="importModalVisible"
        apiFuncName="reviewImport"
        @closeModal="handleCloseModal"
        @resetTable="doUpdateImport"
    />
  </section>
</template>

<script>
import {
  ExportOutlined, ImportOutlined,
} from "@ant-design/icons-vue";
import {nextTick, onMounted, reactive, ref} from "vue";
import FillAppraises from "@/views/home/ResourceManagement/Assignment/assignmentDetails/components/FillAppraises.vue";
import moment from "moment/moment";
import {dictionaryOptionsCode, downloadFile, useTableHeight} from "@/utils/common";
import {useGlobalPropertyHook} from "@/hooks/common";
import { message } from "ant-design-vue";
import {cloneDeep} from "lodash";
import store from "@/store";
import ImportModal from "@/components/ImportModal.vue";
import Tooltip from "@/components/Tooltip.vue";

export default {
  name: "index",
  components: {
    ImportOutlined,
    ExportOutlined, Tooltip,
    ImportModal,
    FillAppraises,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const formState = reactive({
      name: '',
      projectNumOrName:'',
      grade: undefined,
      isCompleted: undefined,
    })
    const formRef = ref();
    const gradeOps = ref([])
    const reviewList = ref([])
    const reviewColumns = [
      {
        title: "唯一编号",
        dataIndex: "uniqueNumber",
        key: "uniqueNumber",
        width: 90,
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "是否已填",
        dataIndex: "isCompleted",
        key: "isCompleted",
        width: 90,
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 140,
        ellipsis: true,
        fixed: "left",
      },
      {
        title: "Grade",
        dataIndex: "grade",
        key: "grade",
        width: 70,
        ellipsis: true,
      },
      {
        title: "Employee Organization",
        dataIndex: "organization",
        key: "organization",
        width: 260,
        ellipsis: true,
      },
      {
        title: "岗位名称",
        dataIndex: "position",
        key: "position",
        width: 120,
        ellipsis: true,
      },
      {
        title: "Project Number",
        dataIndex: "projectNumber",
        key: "projectNumber",
        width: 140,
        ellipsis: true,
      },
      {
        title: "Project Name",
        dataIndex: "projectName",
        key: "projectName",
        width: 300,
        ellipsis: true,
      },
      {
        title: "Actual Billed Hours",
        dataIndex: "actualBilledHours",
        key: "actualBilledHours",
        width: 170,
        ellipsis: true,
      },
      {
        title: "Reviewer",
        dataIndex: "engagementManager",
        key: "engagementManager",
        width: 180,
        ellipsis: true,
      },
      {
        title: "Reviewer Email",
        dataIndex: "engagementEmail",
        key: "engagementEmail",
        width: 180,
        ellipsis: true,
      },{
        title: "Delivery Executive",
        dataIndex: "deliveryExecutive",
        key: "deliveryExecutive",
        width: 160,
        ellipsis: true,
      },
      {
        title: "Attitude",
        dataIndex: "attitude",
        key: "attitude",
        width: 100,
        ellipsis: true,
      },
      {
        title: "English(Optional)",
        dataIndex: "english",
        key: "english",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Skill",
        dataIndex: "skill",
        key: "skill",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Teamwork",
        dataIndex: "teamwork",
        key: "teamwork",
        width: 100,
        ellipsis: true,
      },
      {
        title: "Synthetical score",
        dataIndex: "syntheticalScore",
        key: "syntheticalScore",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Summary",
        dataIndex: "summary",
        key: "summary",
        width: 100,
        ellipsis: true,
      },
      {
        title: "If Key role",
        dataIndex: "ifKeyRole",
        key: "ifKeyRole",
        width: 100,
        ellipsis: true,
      },
      {
        title: "是否有离职风险(Y-H/M/L N-NA)",
        dataIndex: "isRetentionRisk",
        key: "isRetentionRisk",
        width: 250,
        ellipsis: true,
      },
      {
        title: "Actual Reviewer Mail",
        dataIndex: "reviewerMail",
        key: "reviewerMail",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Actual Review Date",
        dataIndex: "reviewDate",
        key: "reviewDate",
        width: 170,
        ellipsis: true,
      },
      {
        title: "Employee Mail",
        dataIndex: "employeeMail",
        key: "employeeMail",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Org 3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Org 4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Org 5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Current code",
        dataIndex: "currentCode",
        key: "currentCode",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Current Project",
        dataIndex: "currentProject",
        key: "currentProject",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Currnet Account",
        dataIndex: "currentAccount",
        key: "currentAccount",
        width: 150,
        ellipsis: true,
      },
      {
        title: "Current Sub p&L",
        dataIndex: "currentSubPl",
        key: "currentSubPl",
        width: 200,
        ellipsis: true,
      },
      {
        title: "Start Date",
        dataIndex: "startDate",
        key: "startDate",
        width: 120,
        ellipsis: true,
      },
      {
        title: "End Date",
        dataIndex: "endDate",
        key: "endDate",
        width: 120,
        ellipsis: true,
      },
      {
        title: "被授权人邮箱",
        dataIndex: "authorizedPersonEmail",
        key: "authorizedPersonEmail",
        width: 200,
        ellipsis: true,
      },
      {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
        width: 80,
        fixed: "right",
      },
    ]
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(pagination);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(pagination);
      },
    });
    const currentState = ref({});
    const updateParams = () => {
      currentState.value = cloneDeep(formState);
    };
    const doSearch = ({ current = 1, pageSize = pagination.pageSize } = {}) => {
      updateParams()
      $api.getAssignmentReviewList({
        pageIndex: current,
        pageSize: pageSize,
        ...formState
       }).then((res) => {
        pagination.current = res.data?.pageIndex + 1;
        pagination.pageSize = res.data?.pageSize;
        pagination.total = res.data?.total;
        reviewList.value = res.data.resource
      });
    };
    const resetForm = () => {
      formRef.value.resetFields();
      selectedState.selectedId = []
      updateParams()
      doSearch();
    };
    const getGradeOps = () => {
      $api.getDictionaryList({params: {dictType: "grade",},}).then((res) => {
        gradeOps.value = dictionaryOptionsCode(res.data.grade);
        gradeOps.value.sort((a,b) => {return a.dictSort - b.dictSort})
        gradeOps.value = gradeOps.value.filter(item => item.dictValue !== 'J1' && item.dictValue !== 'J2' && item.dictValue !== 'J3')
      });
    };

    const reviewClick = (record) => {
      if(record.attitude) {
        appraisesState.value = {
          type: 'unique',
          uniqueNumber: record.uniqueNumber,
          attitude: parseInt(record.attitude),
          skill: parseInt(record.skill),
          english: parseInt(record.english),
          teamwork: parseInt(record.teamwork),
          syntheticalScore: parseInt(record.syntheticalScore),
          keyTalent: record.ifKeyRole,
          reviewDate: moment(record.reviewDate),
          retentionRisk: record.isRetentionRisk,
          summary: record.summary,
        };
      } else {
        appraisesState.value = {
          type: 'unique',
          uniqueNumber: record.uniqueNumber,
          attitude: "",
          skill: "",
          english: "",
          teamwork: "",
          syntheticalScore: "",
          keyTalent: "",
          reviewDate: moment(),
          retentionRisk: "",
          summary: "",
        };
      }
      appraisesVisible.value = true
    }

    const batchReviewClick = () => {
      appraisesState.value = {
        type: 'batch',
        uniqueNumber: '',
        attitude: "",
        skill: "",
        english: "",
        teamwork: "",
        syntheticalScore: "",
        keyTalent: "",
        reviewDate: moment(),
        retentionRisk: "",
        summary: "",
      };
      appraisesVisible.value = true
    }

    const batchRoleauthorization = () => {
      authorizationVisible.value = true
    }
    const exportReviewData = () => {
      $api.getReviewExport(currentState.value).then((res) => {
        downloadFile(res.file, res.filename);
      });
    }
    const uploadReviewData= () => {
      importModalVisible.value = true
    }

    const appraisesVisible = ref(false);
    const closeAppraises = () => {
      appraisesState.value = {};
      appraisesVisible.value = false;
    };
    const submitAppraises = () => {
      const params = {
        attitude: appraisesState.value.attitude,
        english: appraisesState.value.english,
        syntheticalScore: appraisesState.value.syntheticalScore,
        ifKeyRole: appraisesState.value.keyTalent,
        isRetentionRisk: appraisesState.value.retentionRisk,
        skill: appraisesState.value.skill,
        teamwork: appraisesState.value.teamwork,
        reviewDate: appraisesState.value.reviewDate.format("YYYY-MM-DD"),
        summary: appraisesState.value.summary,
        uniqueNumberList: appraisesState.value.type === 'unique' ? [appraisesState.value.uniqueNumber] : selectedState.selectedId
      }
      $api.emAppraises(params).then((res) => {
        doSearch()
        message.success(res.msg);
        selectedState.selectedId = []
        selectedState.selectedList = []
        appraisesVisible.value = false;
      });
    };

    const appraisesState = ref({})
    const selectedState = reactive({
      selectedId: [],
      selectedList: [],
    });
    const onSelect = (record, selected) => {
      if (selected) {
        selectedState.selectedList.push(record);
      } else {
        const delIndex = selectedState.selectedList.findIndex((val) => {
          return val.id === record.id;
        });
        selectedState.selectedList.splice(delIndex, 1);
      }
    };
    const onSelectChange = (selectedRowKeys) => {
      selectedState.selectedId = selectedRowKeys;
    };

    //批量授权
    const authorizationFormRef = ref()
    const authorizationVisible = ref(false)
    const authorizationState = reactive({
      authorizationPeopleState: undefined
    })
    const allempoweredStaffIdOps = ref([])
    const authorizationFormRules = {
      authorizationPeopleState: [
        {
          required: true,
          message: "请选择被授权人",
          trigger: "change",
        },
      ],
    }
    const authorizationCancel = () => {
      authorizationFormRef.value.resetFields();
      authorizationVisible.value = false
    }
    const authorizationConfirm = () => {
      authorizationFormRef.value.validate().then(() => {
        const params = {
          authorizedPersonEmail: authorizationState.authorizationPeopleState.split(';')[1],
          uniqueNumber: selectedState.selectedId,
          name: authorizationState.authorizationPeopleState.split(';')[0]
        }
        $api.assignmentReviewEmpowerCommand(params).then((res) => {
          doSearch()
          message.success(res.msg);
          authorizationFormRef.value.resetFields();
          selectedState.selectedId = []
          selectedState.selectedList = []
          authorizationVisible.value = false
        });
      })
    }
    //被授权人查询
    const getEmpoweredPerson = () => {
      $api.getAuthorizedPerson().then(res => {
        allempoweredStaffIdOps.value = res.data
      })
    }

    const filterOption = (input, option) => {
      return (
          option.children[0].children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
      );
    };

    const isImport = ref(false)
    const importModalVisible = ref(false);
    const handleCloseModal = () => {
      importModalVisible.value = false;
    };
    const doUpdateImport = () => {
      doSearch();
    };

    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };
    onMounted(() => {
      getSize()
      updateParams()
      doSearch()
      getGradeOps()
      getEmpoweredPerson()
      //判断导入按钮权限
      const user = store.state.app.user
      if(user.email === 'ling.a.liu@capgemini.com' || user.email === 'tingting.jiang@capgemini.com' || user.roles[0] === '1'){
        isImport.value = true
      }
    })

    return {
      formState,
      gradeOps,
      reviewList,
      reviewColumns,
      pagination,
      doSearch,
      resetForm,
      reviewClick,
      batchReviewClick,
      batchRoleauthorization,
      exportReviewData,
      appraisesVisible,
      closeAppraises,
      submitAppraises,
      appraisesState,
      selectedState,
      onSelect,
      onSelectChange,
      authorizationVisible,
      authorizationFormRules,
      authorizationState,
      allempoweredStaffIdOps,
      authorizationCancel,
      authorizationConfirm,
      formRef,
      authorizationFormRef,
      tableHeight,
      getSize,
      getGradeOps,
      getEmpoweredPerson,
      currentState,
      updateParams,
      filterOption,
      uploadReviewData,
      isImport,
      importModalVisible,
      handleCloseModal,
      doUpdateImport,
    }
  }
}
</script>

<style scoped lang="less">
.app-container {
  height: 100%;
  width: 100%;
  padding: 20px 20px 0;
  background: #ffffff;
  border-radius: 8px;
}
.authorizationClass {
  .ant-form-item {
    margin-bottom: 0;
  }
}
</style>
